import LandingPage from "./LandingPage";
import { redirect, Route, Routes, useLocation, useParams } from "react-router-dom";
import Success from "./Payments/success";
import Cancel from "./Payments/cancel";
import { useEffect, useState } from "react";
import axios from "axios";
import { Box, Spinner } from "@chakra-ui/react";
import SignupSuccess from "./Extension/Success";

const Loader = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100vh"
  >
    <Spinner size="xl" thickness="4px" color="blue.500" />
  </Box>
);

function DynamicPage() {
  const loc = useLocation();

  useEffect(() => {
    window.location.href = `https://conv.hammoq.com${loc.pathname}`
  }, [loc.pathname]);
  return <Loader />

}
const AllRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/payment/purchase/success" element={<Success />} />
      <Route path="/payment/purchase/cancel" element={<Cancel />} />
      <Route path="/extension/signup/success" element={<SignupSuccess />} />

      {/* Dynamic fallback route */}
      <Route path="*" element={<DynamicPage />} />
    </Routes>
  );
};

export default AllRoutes;
