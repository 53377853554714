import { Box, Grid, HStack, Link, Text, VStack } from "@chakra-ui/react";

const Footer = ({ scrollSmoothHandler }: any) => {
  return (
    <Box
      w={"100%"}
      minHeight={"300px"}
      bgColor={"#101010e0"}
      color={"white"}
      position={"relative"}
    >
      <HStack
        justify={{ base: "left", md: "center" }}
        w={{ base: "90%", lg: "80%" }}
      >
        <Grid
          gridTemplateColumns={{ base: "repeat(2,1fr)", sm: "repeat(3,1fr)" }}
          padding={{ base: "1rem", md: "2rem" }}
          gap={{ base: "2rem", md: "5rem", lg: "10rem", xl: "15rem" }}
        >
          <VStack alignItems={"start"}>
            <Text fontWeight={700}>COMPANY</Text>
            <Text
              cursor={"pointer"}
              onClick={() => {
                scrollSmoothHandler("home");
              }}
            >
              Home
            </Text>
            <Text
              cursor={"pointer"}
              onClick={() => {
                scrollSmoothHandler("intelligent");
              }}
            >
              Fetaures
            </Text>
            <Text
              cursor={"pointer"}
              onClick={() => {
                scrollSmoothHandler("listingspeed");
              }}
            >
              Listing Speed
            </Text>
            <Text
              cursor={"pointer"}
              onClick={() => {
                scrollSmoothHandler("testimonials");
              }}
            >
              Testimonials
            </Text>
          </VStack>
          <VStack alignItems={"start"}>
            <Text fontWeight={700}>LEGAL</Text>
            <Link
              cursor={"pointer"}
              textDecoration={"none"}
              href="https://conv.hammoq.com/privacy-policy"
              isExternal={true}
            >
              Privacy Policy
            </Link>
          </VStack>
          <VStack alignItems={"start"}>
            <Text fontWeight={700}>CONNECT</Text>
            <HStack>
              <Link
                cursor={"pointer"}
                textDecoration={"none"}
                _hover={{ fontWeight: 700 }}
                href="https://www.facebook.com/HAMMOQINC/"
                isExternal={true}
              >
                {/* Facebook */}
                <img
                  src="https://cdn.prod.website-files.com/62e4963b86728d1851e86445/62eff1b73d1cea84ea23eb26_Facebook.svg"
                  loading="lazy"
                  alt=""
                />
              </Link>
              <Link
                cursor={"pointer"}
                textDecoration={"none"}
                _hover={{ fontWeight: 700 }}
                href="https://www.instagram.com/hammoqinc"
                isExternal={true}
              >
                {/* Instagram */}
                <img
                  src="https://cdn.prod.website-files.com/62e4963b86728d1851e86445/62eff1b87ed5f46c85d2f65e_Instagram%201.svg"
                  loading="lazy"
                  alt=""
                />
              </Link>
              <Link
                cursor={"pointer"}
                textDecoration={"none"}
                _hover={{ fontWeight: 700 }}
                href="https://www.linkedin.com/company/hammoq"
                isExternal={true}
              >
                {/* Linkedin */}
                <img
                  src="https://cdn.prod.website-files.com/62e4963b86728d1851e86445/62eff1b7b7ece04d27a41222_Linkedin.svg"
                  loading="lazy"
                  alt=""
                />
              </Link>
              <Link
                cursor={"pointer"}
                textDecoration={"none"}
                _hover={{ fontWeight: 700 }}
                href="https://twitter.com/hammoqapp"
                isExternal={true}
              >
                {/* Twitter */}
                <img
                  src="https://cdn.prod.website-files.com/62e4963b86728d1851e86445/62eff1b7629f18401c5f85cc_Twitter%202.svg"
                  loading="lazy"
                  alt=""
                />
              </Link>
            </HStack>
          </VStack>
        </Grid>
      </HStack>
      <Box padding={"1rem"}></Box>
      <HStack w={"100%"} position={"absolute"} bottom={"0px"} margin={"auto"}>
        <Text m="auto" paddingBottom={"0.5rem"}>
          &#169; 2024 Infinity AI. All rights reserved by Hammoq.
        </Text>
      </HStack>
    </Box>
  );
};

export default Footer;
