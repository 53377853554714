import { Box, Button, Flex, Text } from "@chakra-ui/react";
import Navbar from "../../Components/Navbar";
import checkImage from "../../assets/images/check.png";
import { Helmet } from "react-helmet-async";
import { useEffect, useRef, useState } from "react";
import { FaPlay } from "react-icons/fa";
import { FaArrowDownLong } from "react-icons/fa6";

const SignupSuccess = () => {
  useEffect(() => {
    // Dynamically add the <noscript> tag to the body
    const noscriptTag = document.createElement("noscript");
    noscriptTag.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WHK7GLM8"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.insertBefore(noscriptTag, document.body.firstChild);

    // Cleanup on component unmount
    return () => {
      if (noscriptTag.parentNode) {
        noscriptTag.parentNode.removeChild(noscriptTag);
      }
    };
  }, []);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<any>(null);

  return (
    <>
      <Helmet>
        {/* Google Tag Manager Script */}
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WHK7GLM8');
          `}
        </script>
      </Helmet>
      <Navbar page={"success"} />
      <Box marginTop={"5rem"}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          gap={"5rem"}
          alignItems={"center"}
        >
          <img
            src={checkImage}
            alt="success image"
            width={"5%"}
            height={"5%"}
          />
          <Text fontSize={"2rem"}>
            Signup Successful, Please{" "}
            <Text as="span" fontWeight={600}>
              watch the video
            </Text>{" "}
            for next steps.
          </Text>
        </Box>
        <Box
          display={"flex"}
          margin={"1rem"}
          height={"70vh"}
          justifyContent={"center"}
          position={"relative"}
        >
          <video
            autoPlay
            loop
            controls
            style={{
              width: "auto",
              height: "100%",
              borderRadius: "1rem",
              border: "1px solid",
            }}
            ref={videoRef}
            onPlay={() => {
              setIsPlaying(true);
            }}
            onPause={() => {
              setIsPlaying(false);
            }}
          >
            <source
              src={"/Infinity-AI-Thank-You-Page-Tutorial.mp4"}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          {!isPlaying && (
            <Box position={"absolute"} bottom={"5rem"} left={"18%"}>
              <Box display={"flex"} position={"relative"}>
                <FaArrowDownLong
                  style={{
                    position: "relative",
                    bottom: "-1rem",
                    height: "5rem",
                    width: "8rem",
                  }}
                />
                <Text
                  position={"relative"}
                  left={"-5rem"}
                  top={"-2rem"}
                  fontSize={"2rem"}
                >
                  play/pause
                </Text>
              </Box>
            </Box>
          )}
          {!isPlaying && (
            <Box position={"absolute"} bottom={"5rem"} right={"23%"}>
              <Box display={"flex"} position={"relative"}>
                <Text
                  position={"relative"}
                  right={"-5rem"}
                  top={"-2rem"}
                  fontSize={"2rem"}
                >
                  Volume
                </Text>
                <FaArrowDownLong
                  style={{
                    position: "relative",
                    bottom: "-1rem",
                    height: "5rem",
                    width: "8rem",
                  }}
                />
              </Box>
            </Box>
          )}
          {/* {!isPlaying && (
            <Button
            colorScheme="none"
            position={'absolute'}
            top={'50%'}
            left={'50%'}
            transform={"translate(-50%, -50%)"}
            zIndex={500}
            backgroundColor={"#a3a3a3e0"}
            borderRadius={"50%"}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            color={"#ffd400"}
            width={"120px"}
            height={"120px"}
            fontSize={"5rem"}
            padding={'0px 1px 0px 12px !important'}
            cursor={"pointer"}
            onClick={()=>{
              if(videoRef.current){
                videoRef?.current?.play();
              }
            }}
            >
              <FaPlay />
            </Button>
          )} */}
        </Box>
      </Box>
    </>
  );
};

export default SignupSuccess;
