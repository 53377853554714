import {
  HStack,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import TablePage from "./Components/tablePage";
const dataTable = [
  {
    text: "Building an SEO friendly Title field",
    after: "1 Sec",
    before: "4 Min",
  },
  {
    text: "Brand",
    after: "1 Sec",
    before: "5 Sec",
  },
  {
    text: "Condition",
    after: "1 Sec",
    before: "3 Sec",
  },
  {
    text: "Gender",
    after: "1 Sec",
    before: "3 Sec",
  },
  {
    text: "Body Style",
    after: "1 Sec",
    before: "7 Sec",
  },
  {
    text: "US Size",
    after: "1 Sec",
    before: "16 Sec",
  },
  {
    text: "UK Size",
    after: "1 Sec",
    before: "4 Sec",
  },
  {
    text: "Color",
    after: "1 Sec",
    before: "3 Sec",
  },
  {
    text: "Department",
    after: "1 Sec",
    before: "3 Sec",
  },
  {
    text: "Pattern",
    after: "1 Sec",
    before: "3 Sec",
  },
  {
    text: "Occasion",
    after: "1 Sec",
    before: "3 Sec",
  },
  {
    text: "Theme",
    after: "1 Sec",
    before: "5 Sec",
  },
  {
    text: "Closure",
    after: "1 Sec",
    before: "3 Sec",
  },
  {
    text: "Material",
    after: "1 Sec",
    before: "3 Sec",
  },
  {
    text: "Features",
    after: "1 Sec",
    before: "4 Sec",
  },
  {
    text: "Season",
    after: "1 Sec",
    before: "3 Sec",
  },
  {
    text: "UPC",
    after: "1 Sec",
    before: "3 Sec",
  },
  {
    text: "Item Type",
    after: "1 Sec",
    before: "14 Sec",
  },
  {
    text: "Sub-Style",
    after: "1 Sec",
    before: "10 Sec",
  },
  {
    text: "Main Purpose",
    after: "1 Sec",
    before: "10 Sec",
  },
  {
    text: "Band Size",
    after: "1 Sec",
    before: "7 Sec",
  },
  {
    text: "Description with Formating",
    after: "1 Sec",
    before: "3 Min",
  },
];
const LisintgSpeedInfo = ({ListingSpeedRef}:any) => {
  const headingFontSize = useBreakpointValue({
    base: "2rem",
    md: "3rem",
    lg: "4rem",
    xl: "5rem",
  });
  const subheadingFontSize = useBreakpointValue({
    base: "1.5rem",
    md: "2rem",
    lg: "2.5rem",
  });
  const statFontSize = useBreakpointValue({
    base: "2.5rem",
    md: "3rem",
    lg: "3.5rem",
  });
  const cardWidth = useBreakpointValue({ base: "100%", md: "90%", lg: "80%",xl:"80%",'2xl':'70%' });
  const hStackWidth = useBreakpointValue({
    base: "100%",
    md: "90%",
    lg: "70%",
  });

  return (
    <VStack gap={"0rem"} position={'relative'} ref={ListingSpeedRef}>
      <Stack
        spacing={"2rem"}
        paddingY={"2rem"}
        bgColor={"#ffdc001a"}
        width={"100%"}
        paddingTop={"4rem"}
        paddingBottom={"4rem"}
        textAlign="center"
      >
        <VStack lineHeight={"5.5rem"} spacing={4}>
          <Text
            fontStyle={"normal"}
            fontWeight={700}
            fontSize={headingFontSize}
            lineHeight={"3.6rem"}
            display={"flex"}
            alignItems={"center"}
            color={'#393939'}
          >
            Listing Speed like never before
          </Text>
          <Text
            color={"gray.500"}
            fontWeight={600}
            lineHeight={{base:"1.5rem",xl:"3.6rem"}}
            fontSize={subheadingFontSize}
          >
            AI powered listings with InfinityAI
          </Text>
        </VStack>

        <HStack
          w={hStackWidth}
          margin={"auto"}
          gap={"1rem"}
          justify={"center"}
          flexWrap={{base:"wrap",xl:"nowrap"}}
        >
          <HStack
            justify={"end"}
            w={{ base: "90%", xl: "45%" }}
            // p={2}
          >
            <VStack
              border={"1rem solid #CCFCB6"}
              rounded={"xl"}
              fontWeight={600}
              backgroundColor={"white"}
              paddingY={"2rem"}
              paddingX={"1rem"}
              w={cardWidth}
            >
              <Text>New Lister with Infinity AI</Text>
              <Text fontSize={statFontSize}>{"<"} 1 Min</Text>
              <Text>to list a product live</Text>
            </VStack>
          </HStack>

          <HStack
            justify={"start"}
            w={{ base: "90%", xl: "45%" }}
            // p={2}
          >
            <VStack
              border={"1rem solid #FFEDED"}
              rounded={"xl"}
              fontWeight={600}
              backgroundColor={"white"}
              paddingY={"2rem"}
              paddingX={"1rem"}
              w={cardWidth}
            >
              <Text>An Average Lister takes</Text>
              <Text fontSize={statFontSize}>10 Mins</Text>
              <Text>to list a product live</Text>
            </VStack>
          </HStack>
        </HStack>
      </Stack>

      <Stack bgColor={"#101010e0"} w={"100%"} zIndex={"-9999"} padding={"1rem"}>
        <TablePage dataTable={dataTable} />
      </Stack>
    </VStack>
  );
};

export default LisintgSpeedInfo;
