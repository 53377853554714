import { Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionText = motion(Text);


const FadeInLeft = ({text,ParentElement}:any) => {
    const letterVariants = {
        hidden: { opacity: 0, x: -20 }, // Start each letter off-screen to the left
        visible: { opacity: 1, x: 0 }, // Animate each letter to full opacity and original position
      };
  return (
    text.split("").map((letter:string,index:number)=>{
        return <MotionText
        key={index}
        as={'span'}
        variants={letterVariants}
        // display="inline-block"
        //["2.5rem","3rem","3.5rem","5rem"]
        fontSize={{base:"2.5rem",md:"3rem",lg:"3.5rem",xl:"4rem",'2xl':"5rem"}}
        fontWeight={800}
      >
        {ParentElement?<ParentElement>{letter}</ParentElement>:letter}
      </MotionText>
    })
  )
}

export default FadeInLeft