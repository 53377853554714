import { Box, HStack, useBreakpointValue } from "@chakra-ui/react";
import Marquee from "react-fast-marquee";
import uprightLogo from "../../../../assets/svg/upright-logo.svg";
import ebayLogo from "../../../../assets/svg/ebay-logo.svg";
import shopifyLogo from "../../../../assets/svg/shopify_glyph.svg";
import shopgoodwillLogo from "../../../../assets/svg/shopgoodwill.svg";
import Logocard from "../FeatureSection/Components/logocard";

const MultiMarketSupport = () => {
  const logoSize = useBreakpointValue({ base: '100px', md: '150px', lg: '180px',xl:"200px" });
  const marqueeSpeed = useBreakpointValue({ base: 30, md: 40, lg: 50 }); // Adjusts speed based on screen size
  const paddingSize = useBreakpointValue({ base: '0.5rem', md: '1rem' }); // Padding between logos on mobile vs desktop

  return (
    <HStack gap={0} w={"100%"} padding={{base:"1rem",md:"3rem",lg:"5rem"}} position={'relative'}>
      <Marquee speed={marqueeSpeed} gradient={false}>
        <Box padding={paddingSize}>
          <Logocard logo={uprightLogo} height={logoSize} width={logoSize} />
        </Box>
        <Box padding={paddingSize}>
          <Logocard logo={ebayLogo} height={logoSize} width={logoSize} />
        </Box>
        <Box padding={paddingSize}>
          <Logocard logo={shopifyLogo} height={logoSize} width={logoSize} imageWidth={'50%'} />
        </Box>
        <Box padding={paddingSize}>
          <Logocard logo={shopgoodwillLogo} height={logoSize} width={logoSize} />
        </Box>
        <Box padding={paddingSize}>
          <Logocard logo={uprightLogo} height={logoSize} width={logoSize} />
        </Box>
        <Box padding={paddingSize}>
          <Logocard logo={ebayLogo} height={logoSize} width={logoSize} />
        </Box>
        <Box padding={paddingSize}>
          <Logocard logo={shopifyLogo} height={logoSize} width={logoSize} imageWidth={'50%'} />
        </Box>
        <Box padding={paddingSize}>
          <Logocard logo={shopgoodwillLogo} height={logoSize} width={logoSize} />
        </Box>
        <Box padding={paddingSize}>
          <Logocard logo={uprightLogo} height={logoSize} width={logoSize} />
        </Box>
        <Box padding={paddingSize}>
          <Logocard logo={ebayLogo} height={logoSize} width={logoSize} />
        </Box>
        <Box padding={paddingSize}>
          <Logocard logo={shopifyLogo} height={logoSize} width={logoSize} imageWidth={'50%'} />
        </Box>
        <Box padding={paddingSize}>
          <Logocard logo={shopgoodwillLogo} height={logoSize} width={logoSize} />
        </Box>
      </Marquee>
    </HStack>
  );
};

export default MultiMarketSupport;
