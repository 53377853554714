// theme.js
import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

// Define custom breakpoints
const breakpoints = createBreakpoints({
  base: "0em",
  sm: "30em", // 480px
  md: "48em", // 768px
  lg: "62em", // 992px
  xl: "80em", // 1280px
  "2xl": "96em", // 1536px
  "3xl": "120em", // 1920px - Custom breakpoint
});

// Extend the theme
const theme = extendTheme({
  breakpoints,
});

export default theme;
