import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import Navbar from "../../Components/Navbar";
import LottieAnimation from "../../Components/LottieAnimation";
import success from "../../assets/animations/success.json";

function Success() {
  return (
    <Box>
      <Navbar />
      <VStack>
        <HStack>
          <Text fontSize={"2rem"} fontWeight={800}>
            Payment Successful
          </Text>
        </HStack>
        <LottieAnimation animationData={success} />
      </VStack>
    </Box>
  );
}

export default Success;
