import { HStack, Text, useBreakpointValue } from "@chakra-ui/react";

type Props = {
  text: string;
  after: string;
  before: string;
  title?: boolean;
};

const TableData = ({ text, after, before, title = false }: Props) => {
  let textSize = useBreakpointValue(title?{base:"0.75rem",lg:"1rem",xl:"1.5rem"}:{base:"0.55rem",lg:"0.95rem",xl:"1rem"})
  return (
    <HStack
      w={"100%"}
      gap={"2%"}
      margin={"auto"}
      bgColor={"white"}
      padding={"0.15rem"}
    >
      <HStack
        w={"70%"}
        bgColor={title ? "#95959570" : "#ffdc0030"}
        padding={"0.25rem"}
        justify={title ? "center" : "left"}
        fontWeight={title ? 600 : 500}
        fontSize={textSize}
        rounded={"md"}
        letterSpacing={"0.1rem"}
      >
        <Text paddingLeft={"1rem"}>{text}</Text>
      </HStack>
      <HStack
        w={"13%"}
        bgColor={"green.200"}
        color={"green.600"}
        fontWeight={title ? 600 : 500}
        fontSize={textSize}
        justify={"center"}
        rounded={"md"}
      >
        <Text paddingY={"0.25rem"}>{after}</Text>
      </HStack>
      <HStack
        w={"13%"}
        bgColor={"red.200"}
        color={"red.600"}
        fontWeight={title ? 600 : 500}
        fontSize={textSize}
        justify={"center"}
        rounded={"md"}
      >
        <Text paddingY={"0.25rem"}>{before}</Text>
      </HStack>
    </HStack>
  );
};

export default TableData;
