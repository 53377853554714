import { Box, HStack, Text } from "@chakra-ui/react";
import TableData from "./tableData";

type Props = {
  dataTable: {
    text: string;
    after: string;
    before: string;
  }[];
};

const TablePage = ({ dataTable }: Props) => {
  return (
    <Box
      width={{base:"100%",'2xl':"40%",xl:"50%",lg:"65%",md:"80%"}}
      margin={"auto"}
      style={{ backgroundColor: "#10101000" }}
      padding={{base:"0.5rem",xl:"1rem"}}
      position={"relative"}
      zIndex={"-999"}
    >
      <Box
        w={"20%"}
        height={{base:"2rem",lg:"3rem"}}
        bgColor={"white"}
        position={"absolute"}
        right={{base:"0.5rem",xl:"1rem"}}
        borderTopRadius={"2xl"}
      ></Box>
      <Box
        width={{base:"30%",xl:"24%"}}
        height={{base:"2rem",lg:"3rem"}}
        right={{base:"18%",xl:"20%"}}
        position={"absolute"}
        background="linear-gradient(120deg, #10101000 50%, white 50%)"
      />

      <Box padding={{base:"1rem",lg:"1.5rem"}} w={"80%"}></Box>

      <Box position={"absolute"} top={{base:"1.5rem",xl:"2rem"}} right={{base:"0.65rem",xl:"1rem"}} w={"26.5%"}>
        <HStack
          w={"100%"}
          justify={"space-evenly"}
          fontSize={{base:"0.45rem",lg:"0.85rem"}}
          lineHeight={{base:"0.45rem",lg:"0.85rem"}}
        >
          <Text fontWeight={600} color={"green"} w={"50%"}>
            New Lister
            <br />
            With Infinity
            <br />
            AI
          </Text>
          <Text fontWeight={600} color={"red"} w={"50%"}>
            With-Out
            <br />
            Infinity
            <br />
            AI
          </Text>
        </HStack>
      </Box>
      {/* <Box
        padding={"0.5rem"}
        w={"100%"}
        bgColor={"white"}
        borderTopLeftRadius={"2xl"}
      ></Box> */}
      <Box padding={{base:"1.5rem 0.5rem 1rem 0.5rem",xl:"1.5rem 1rem 1rem 1rem"}} marginTop={'-0.75rem'} bgColor={"white"} borderTopLeftRadius={"2xl"}  borderBottomRadius={"2xl"}>
        <TableData
          title={true}
          text={"Total Time (MM:SS)"}
          after={"00:22"}
          before={"08:48"}
        />
        {dataTable.length &&
          dataTable.map((item) => (
            <TableData
              text={item.text}
              after={item.after}
              before={item.before}
            />
          ))}
      </Box>
    </Box>
  );
};

export default TablePage;
