import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from "@chakra-ui/react";

const qna = [
  {
    q: "1. How many free credits does a first time user receive ?",
    a: "Every new user receives 100 free credits",
  },
  {
    q: "2. Can I install the app on iOS ?",
    a: "Infinity AI is a Google Chrome Extension , so you can find it on the Google chrome store and install it on a Chrome browser on Windows or Mac",
  },
  {
    q: "3. Is there a limit to number of Autofills ?",
    a: "No, after you exhaust the 100 free credits , you can choose to add extra credits or upgrade plan according to your usage, please checkout the pricing for more details.",
  },
  {
    q: "4. What marketplaces does Infinity AI support?",
    a: "Currently, Infinity AI supports eBay and others, and several other popular platforms. Check our website for the latest updates.",
  },
];

const Faq = ({FaqRef}:any) => {
  return (
    <Box
    ref={FaqRef}
      margin={"auto"}
      paddingX={{base:"1rem",lg:"5rem"}}
      paddingY={"4rem"}
      bgColor={"gray.100"}
      position={'relative'}
    >
      <Box w={{base:"100%",lg:"70vw"}} margin={"auto"}>
        <Text
          fontSize={"2rem"}
          letterSpacing={"0.15rem"}
          textAlign={"center"}
          paddingBottom={"2rem"}
        >
          Frequently Asked Question
        </Text>
        <Box bgColor={"white"} boxShadow={"lg"} rounded={"xl"}>
          <Accordion
            allowMultiple
            defaultIndex={[0, 1, 2, 3, 4]}
            rounded={"xl"}
          >
            {qna.length > 0 &&
              qna.map((item) => (
                <AccordionItem>
                  <AccordionButton
                    border={"none"}
                    fontSize={"1.25rem"}
                    outline={"none"}
                  >
                    <Box as="span" flex="1" textAlign="left">
                      <Text fontWeight={600}>{item.q}</Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel bgColor={"gray.50"}>{item.a}</AccordionPanel>
                </AccordionItem>
              ))}
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
};

export default Faq;
